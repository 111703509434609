<template>
  <div class="pay">
    <BaseHeaderBar
      title="Kode pembayaran"
      :showCustomerService="false"
      :showBackButton="true"
      bgColor="#fff"
      color="#333"
    />
    <div class="pay_box">
      <div class="pay-amount">
        <p class="label">Jumlah pembayaran</p>
        <p class="amount">
          Rp{{ formatNumberWithCommas(payInfo.padryAmount) }}
        </p>
      </div>
      <div class="pay-info-list">
        <div class="info">
          <p class="label">Nama Bank</p>
          <p class="value">{{ payInfo.padryChannel }}</p>
        </div>
        <div class="info">
          <p class="label">Metode pembayaran</p>
          <p class="value">{{ payInfo.padryWay }}</p>
        </div>
        <div class="info">
          <p class="label">Masa berlaku</p>
          <p class="value">{{ payInfo.lodrseTime }}</p>
        </div>
        <div
          class="info"
          v-clipboard:copy="payInfo.padrymentCode"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
        >
          <p class="label">Kode pembayaran</p>
          <p class="value">{{ payInfo.padrymentCode }}</p>
          <img class="copy-btn" src="@/assets/img/copy-btn.png" />
        </div>
      </div>
    </div>

    <div class="extra-info">
      <div class="extra_title">Hal yang perlu diperhatikan</div>
      <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">
        {{ item }}
      </p>
      <div class="extra_title">Cara Pembayaran</div>
      <p
        v-for="(item, index) in payInfo.redrpaymentProcess[0].step"
        :key="index"
      >
        {{ item }}
      </p>
    </div>
    <button type="button" class="paid-btn" @click="onPaid">
      Saya sudah melakukan pembayaran
    </button>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
      class="repayment-success-dialog"
    >
      <p class="tip">Pembayaran berhasil！</p>
      <img src="@/assets/img/huankuanchenggong.png" alt="" />
      <button
        v-if="payInfo.padryCodeid"
        type="button"
        class="ok-btn"
        @click="onConfirm"
      >
        ok
      </button>
      <button type="button" class="ok-btn" @click="onConfirm">ok</button>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      activate: 1
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/home')
    }
  }
}
</script>

<style scoped lang="less">
.pay {
  min-height: 100vh;
  padding-bottom: 80px;
}
.pay_box {
  background: #155c2d;
  border-radius: 35px;
}
.pay-amount {
  padding-top: 40px;

  text-align: center;
  .amount {
    font-weight: 400;
    font-size: 56px;
    color: #ffffff;
  }
  .label {
    font-weight: 400;
    font-size: 30px;
    color: #ffffff;
    margin-bottom: 24px;
  }
}

.pay-info-list {
  padding: 0 30px;
  margin-left: 30px;
  margin-right: 30px;
  height: 323px;

  .info {
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
  }

  .copy-btn {
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
  }

  p {
    font-weight: 600;
  }

  & > .label,
  & > .value {
    font-size: 26px;
    color: #001c44;
  }
}

.extra-info {
  padding-left: 73px;
  padding-right: 71px;
  .extra_title {
    font-weight: 400;
    font-size: 26px;
    color: #333333;
    margin: 24px 0;
  }
  p {
    font-weight: 400;
    font-size: 26px;
    color: #666666;
    line-height: 37px;
  }
}

.tab-content {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-y: scroll;
  overflow: auto;
  height: 350px;

  p {
    font-size: 29px;
    font-weight: 400;
    color: #fff;
    line-height: 46px;
    word-wrap: break-word;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }
}

.paid-btn {
  .submit-btn;
  width: 650px;
  margin-top: 140px;
  font-size: 34px;
}

.repayment-success-dialog {
  img {
    width: 199px;
    margin: 0 auto;
    margin-top: 40px;
  }
}

.tip {
  font-weight: 500;
  font-size: 30px;
  color: #1c0a1b;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.ok-btn {
  .submit-btn;
  width: 520px;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
